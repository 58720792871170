import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { ActivationEnd, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import {
  actionLoadInitialSettings,
  actionSetMapComposition,
} from '@core/api/api.actions';
import { selectAPISettings } from '@core/api/api.selectors';

/**
 * main component
 */
@Component({
  selector: 'gogis-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private instanceTitle: string;

  private destroyRef = inject(DestroyRef);

  /** @ignore */
  constructor(
    private router: Router,
    private title: Title,
    private store: Store,
    private translateService: TranslateService,
  ) {
    // Add available languages
    this.translateService.addLangs(['en', 'lv', 'ru', 'uz']);

    this.setMapComposition();

    this.store.dispatch(actionLoadInitialSettings());

    // Update page title on view changes.
    this.router.events
      .pipe(
        filter((event) => event instanceof ActivationEnd),
        tap(() => {
          this.updateTitle();
        }),
        takeUntilDestroyed(),
      )
      .subscribe();

    // Update page title on language changes.
    this.translateService.onLangChange
      .pipe(
        tap(() => {
          this.updateTitle();
        }),
        takeUntilDestroyed(),
      )
      .subscribe();

    this.store
      .select(selectAPISettings)
      .pipe(
        filter((attributesSettings) => !!attributesSettings),
        take(1),
        takeUntilDestroyed(),
      )
      .subscribe((attributesSettings) => {
        this.translateService.use(attributesSettings.language.substring(0, 2));
        this.instanceTitle = attributesSettings.title;
      });
  }

  private setMapComposition(): void {
    this.store.dispatch(
      actionSetMapComposition({
        compositionName: new URL(location.href).searchParams.get('composition'),
      }),
    );
  }

  private updateTitle(): void {
    let lastChild = this.router.routerState.snapshot.root;
    while (lastChild.children.length) {
      lastChild = lastChild.children[0];
    }
    const data = lastChild.data;

    if (data.title) {
      combineLatest([
        this.translateService.get(data.title),
        this.store.select(selectAPISettings),
      ])
        .pipe(
          filter(([, apiSettings]) => !!apiSettings),
          take(1),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(([translatedTitle, apiSettings]) => {
          this.title.setTitle(`${translatedTitle} - ${apiSettings.title}`);
        });
    } else {
      this.title.setTitle(`${this.instanceTitle}`);
    }
  }
}
